<template>
  <div>
    <!-- <h3>Kurspläne</h3>
    <table class="table table-bordered table-sm table-hover">
      <thead class="thead-dark">
        <tr>
          <th class="text-center">#</th>
          <th>Name</th>
          <th class="text-center">Online</th>
          <th class="text-center">Neustart</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(device, index) in kursplaene" :key="device.id">
          <th class="text-center" width="5%">{{ index + 1 }}</th>
          <td>{{ device.name }}</td>
          <td class="text-center" width="10%">
            <i
              class="fa fa-fw"
              :class="{
                'fa-check-circle text-success': device.status === 200,
                'fa-times-circle text-danger': device.status !== 200,
              }"
            ></i>
          </td>
          <td class="text-center" width="10%">
            <i
              class="fa fa-fw fa-refresh cursor-pointer"
              @click="restartApp(device.id, device.appId)"
              :class="{ 'fa-spin': restartingDevice[device.id] }"
              :disabled="restartingDevice[device.id]"
            ></i>
          </td>
        </tr>
      </tbody>
    </table> -->
    <h3>Kundenstopper</h3>
    <table class="table table-bordered table-sm table-hover">
      <thead class="thead-dark">
        <tr>
          <th class="text-center" width="5%">#</th>
          <th>Name</th>
          <th class="text-center">Online</th>
          <th class="text-center">Neustart</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(device, index) in kundenstopper" :key="device.id">
          <th class="text-center">{{ index + 1 }}</th>
          <td>{{ device.name }}</td>
          <td class="text-center" width="10%">
            <i
              class="fa fa-fw"
              :class="{
                'fa-check-circle text-success': device.status === 200,
                'fa-times-circle text-danger': device.status !== 200,
              }"
            ></i>
          </td>
          <td class="text-center" width="10%">
            <i
              class="fa fa-fw fa-refresh cursor-pointer"
              :class="{ 'fa-spin': restartingDevice[device.id] }"
              :disabled="restartingDevice[device.id]"
              @click="restartDevice(device.id)"
            ></i>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import { firestore, functions } from '../firebase';

const restartDevice = functions.httpsCallable('httpsRestartDevice');
const restartApp = functions.httpsCallable('httpsRestartApp');

export default {
  data() {
    return {
      devices: [],
      restartingDevice: {},
    };
  },
  async created() {
    firestore
      .collection('devices')
      .orderBy('name')
      .onSnapshot((snapshot) => {
        this.devices = snapshot.docs.map((doc) => {
          return {
            id: doc.id,
            ...doc.data(),
          };
        });
      });
  },
  methods: {
    async restartDevice(uuid) {
      if (!this.restartingDevice[uuid])
        if (confirm('Bist du sicher, das du dieses Gerät neustarten willst?')) {
          this.restartingDevice = {
            ...this.restartingDevice,
            [uuid]: true,
          };

          try {
            await restartDevice({ uuid });
          } catch (e) {
            alert('Gerät ist momentan nicht erreichbar');

            throw e;
          } finally {
            this.restartingDevice = {
              ...this.restartingDevice,
              [uuid]: false,
            };
          }
        }
    },
    async restartApp(uuid, appId) {
      if (!this.restartingDevice[uuid]) {
        if (confirm('Bist du sicher, das du dieses Gerät neustarten willst?')) {
          this.restartingDevice = {
            ...this.restartingDevice,
            [uuid]: true,
          };

          try {
            await restartApp({ uuid, appId });
          } catch (e) {
            alert('Gerät ist momentan nicht erreichbar');

            throw e;
          } finally {
            alert('Gerät wurde neugestartet');
            this.restartingDevice = {
              ...this.restartingDevice,
              [uuid]: false,
            };
          }
        }
      }
    },
  },
  computed: {
    kundenstopper() {
      return this.devices.filter((device) => {
        return device.type === 'Kundenstopper';
      });
    },
    kursplaene() {
      return this.devices.filter((device) => {
        return device.type === 'Kursplan';
      });
    },
  },
};
</script>
